import { APP_INITIALIZER, EnvironmentProviders, NgZone, makeEnvironmentProviders } from '@angular/core';
import { PlatformService } from '@rma/generic/util/environment';
import { Angulartics2GoogleAnalytics, Angulartics2GoogleGlobalSiteTag } from 'angulartics2';

function initialiseGoogleTracking(
  platformService: PlatformService,
  angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
  angulartics: Angulartics2GoogleGlobalSiteTag,
  zone: NgZone,
): () => void {
  return () => {
    if (platformService.isTrackable) {
      zone.runOutsideAngular(() => {
        // GA Universal. Remove after July 1st 2023
        angulartics2GoogleAnalytics.startTracking();
        // GA 4 tracking. Can we implement this ourselves?
        angulartics.startTracking();
      });
    }
  };
}

export function provideRmaGoogleTracking(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: initialiseGoogleTracking,
      deps: [PlatformService, Angulartics2GoogleAnalytics, Angulartics2GoogleGlobalSiteTag, NgZone],
      multi: true,
    },
  ]);
}
