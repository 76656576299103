import { Injectable } from '@angular/core';
import { DynamicAssetLoaderService, DynamicLoaderInlineAssets } from '@rma/generic/util/dynamic-asset-loader';
import { Environment } from '@rma/generic/util/environment';

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelScriptService {
  constructor(
    private readonly environment: Environment,
    private readonly dynamicAssetLoader: DynamicAssetLoaderService,
  ) {}

  public appendScript(): void {
    if (this.environment.tracking.facebookPixelId) {
      const pixelScript = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${this.environment.tracking.facebookPixelId}');
      fbq('track', 'PageView');
      `;
      this.dynamicAssetLoader.appendInlineAsset(DynamicLoaderInlineAssets.FacebookPixelScript, pixelScript);

      const customInnerHtml = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${this.environment.tracking.facebookPixelId}&ev=PageView&noscript=1"/>`;
      this.dynamicAssetLoader.appendInlineAssetWithCustomContent(DynamicLoaderInlineAssets.FacebookPixel, 'noscript', customInnerHtml);
    }
  }
}
