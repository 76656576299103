import { NgModule } from "@angular/core";
import { CookiesOptionsService, COOKIES_OPTIONS } from "../cookies-options.service";
import { CookiesService } from "../cookies.service";
import { BrowserCookiesService } from "./browser-cookies.service";
import * as i0 from "@angular/core";
var BrowserCookiesModule = /** @class */function () {
  function BrowserCookiesModule() {}
  BrowserCookiesModule.forRoot = function (options) {
    if (options === void 0) {
      options = {};
    }
    return {
      ngModule: BrowserCookiesModule,
      providers: [{
        provide: COOKIES_OPTIONS,
        useValue: options
      }, CookiesOptionsService, {
        provide: CookiesService,
        useClass: BrowserCookiesService
      }]
    };
  };
  BrowserCookiesModule.ɵmod = i0.ɵɵdefineNgModule({
    type: BrowserCookiesModule
  });
  BrowserCookiesModule.ɵinj = i0.ɵɵdefineInjector({
    factory: function BrowserCookiesModule_Factory(t) {
      return new (t || BrowserCookiesModule)();
    }
  });
  return BrowserCookiesModule;
}();
export { BrowserCookiesModule };
/*@__PURE__*/
(function () {
  void 0;
})();
