import { ModuleWithProviders, NgModule } from '@angular/core';
import { RMA_AB_TEST_CONFIG } from '../domain/injection-tokens';
import { AbTestOptions } from '../domain/models/ab-test-options.model';
import { AbTestsService } from '../util-ab-test/ab-test.service';
import { AbTestStorageService } from '../util-storage-service/base-storage.service';
import { LocalStorageService } from '../util-storage-service/local-storage.service';
import { AbTestClassDirective } from './ab-test-class.directive';
import { AbIfDirective } from './ab-test-if.directive';
import { AbTestDirective } from './ab-test.directive';

@NgModule({
  imports: [AbTestDirective, AbIfDirective, AbTestClassDirective],
  exports: [AbTestDirective, AbIfDirective, AbTestClassDirective],
})
export class AbTestModule {
  static forRoot(configs: AbTestOptions[]): ModuleWithProviders<AbTestModule> {
    const providers = configs.map((config) => ({ provide: RMA_AB_TEST_CONFIG, useValue: config, multi: true }));
    return {
      ngModule: AbTestModule,
      providers: [AbTestsService, { provide: AbTestStorageService, useClass: LocalStorageService }, ...providers],
    };
  }
}
