import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RouterModule } from '@angular/router';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { IsTranslationObjectPipe } from '@rma/generic/orc/translation';
import { FooterLink } from '../domain/footer-links.model';
import { FOOTER_TRANSLATION_SCOPE } from '../domain/translation-scopes';

@Component({
  selector: 'rma-footer-link',
  standalone: true,
  imports: [RouterModule, TranslocoModule, IsTranslationObjectPipe],
  templateUrl: './footer-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope(FOOTER_TRANSLATION_SCOPE)],
})
export class FooterLinkComponent implements OnChanges {
  @Input({ required: true })
  footerLink!: FooterLink;

  @Input()
  theme: 'primary' | 'none' = 'none';

  protected readonly scope = `${FOOTER_TRANSLATION_SCOPE}.links`;

  protected linkThemeStyles = 'text-steel-90 no-underline';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['theme']) {
      switch (this.theme) {
        case 'primary':
          this.linkThemeStyles = 'text-white hover:text-highlight-blue no-underline font-normal';
          break;
        case 'none':
          this.linkThemeStyles = 'text-steel-90 no-underline';
          break;
      }
    }
  }
}
