import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RmaUrlsService } from '@rma/generic/util/environment';
import { Observable } from 'rxjs';

export interface LocationFooterLink {
  name: string;

  sales: LocationFooterLinkUrl;
  finance: LocationFooterLinkUrl;
  leasing: LocationFooterLinkUrl;
}

export interface LocationFooterLinkUrl {
  agentUrl?: string;
  agencyUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly rmaUrls: RmaUrlsService,
  ) {}

  public getLocationFooterLinks(): Observable<LocationFooterLink[]> {
    const url = this.rmaUrls.apiUrl('v1/Layout/FooterLinks');
    return this.httpClient.get<LocationFooterLink[]>(url);
  }
}
