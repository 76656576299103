import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConnectedPosition, OverlayRef } from '@angular/cdk/overlay';
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  OnDestroy,
  signal,
  TemplateRef,
  untracked,
  ViewContainerRef,
} from '@angular/core';
import { RmaIconComponent } from '@rma/generic/ui/icon';
import type { RmaOverlayService } from '@rma/generic/ui/overlay';
import { LazyInject } from '@rma/generic/util/lazy-inject';
import { firstValueFrom, take } from 'rxjs';
import { MenuTheme, ThemeConfig } from './theme';

const indicatorRotate = trigger('indicatorRotate', [
  state('collapsed, void', style({ transform: 'rotate(0deg)' })),
  state('expanded', style({ transform: 'rotate(180deg)' })),
  transition('expanded <=> collapsed, void => collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
]);

type MenuState = 'collapsed' | 'expanded';
export type MenuMode = 'inline' | 'popover';

@Component({
  selector: 'rma-menu',
  standalone: true,
  imports: [RmaIconComponent],
  templateUrl: './menu.component.html',
  animations: [indicatorRotate],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnDestroy {
  private readonly lazyInjector = inject(LazyInject);
  private readonly elementRef = inject(ElementRef);
  private readonly vcr = inject(ViewContainerRef);
  private readonly location = inject(Location);

  public readonly text = input.required<string>();
  public readonly theme = input<MenuTheme>('primary');
  public readonly mode = input.required<MenuMode>();
  public readonly items = input<TemplateRef<unknown>>();

  public readonly indicator = signal<MenuState>('collapsed');

  public readonly buttonTheme = computed(() => {
    const config = ThemeConfig[this.theme()];
    const indicator = this.indicator();
    const mode = this.mode();

    const classes: string[] = [];

    if (indicator === 'collapsed') {
      classes.push(config.buttonColor, this.getHoverStyle(config.buttonHoverColor));
    } else {
      classes.push(config.buttonHoverColor);
    }

    if (mode === 'popover') {
      classes.push(this.getHoverStyle(config.buttonHoverBackground), 'py-2');
    }

    return classes.join(' ');
  });

  private overlayService?: RmaOverlayService;
  private overlayRef?: OverlayRef;

  constructor() {
    this.location.onUrlChange(() => this.collapse());

    effect(() => {
      const indicator = this.indicator();

      untracked(() => {
        if (this.mode() === 'popover') {
          const items = this.items();

          if (items) {
            this.showMenuItems(indicator === 'expanded', items);
          } else {
            console.warn('you must supply items when mode is popover');
          }
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.overlayRef?.detach();
    this.overlayRef?.dispose();
  }

  protected onExpandCollapse() {
    this.indicator.update((indicator) => (indicator === 'collapsed' ? 'expanded' : 'collapsed'));
  }

  private getHoverStyle(cssClass: string) {
    return `hover:${cssClass}`;
  }

  private collapse() {
    this.indicator.set('collapsed');
  }

  private async getOverlayService() {
    if (!this.overlayService) {
      const overlayService = await this.lazyInjector.get<RmaOverlayService>(() =>
        import('@rma/generic/ui/overlay').then((m) => m.RmaOverlayService),
      );

      this.overlayService = overlayService;
    }

    return this.overlayService;
  }

  private async showMenuItems(show: boolean, templateRef: TemplateRef<unknown>) {
    if (show) {
      const overlayService = await this.getOverlayService();

      const connectedPosition: ConnectedPosition = {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
      };

      const { overlayRef } = overlayService.open(this.vcr, templateRef, {
        hasBackdrop: true,
        disposeOnNavigation: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        scrollStrategy: overlayService.overlay.scrollStrategies.close(),
        positionStrategy: overlayService.overlay.position().flexibleConnectedTo(this.elementRef).withPositions([connectedPosition]),
      });

      this.overlayRef = overlayRef;

      overlayRef
        .detachments()
        .pipe(take(1))
        .subscribe(() => this.collapse());

      await firstValueFrom(overlayRef.backdropClick()).then(() => {
        overlayRef.detach();
        this.collapse();
      });
    } else {
      this.overlayRef?.detach();
    }
  }
}
