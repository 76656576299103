import { InMemoryScrollingOptions, RouterConfigOptions } from '@angular/router';

export const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

export const routerConfig: RouterConfigOptions = {
  onSameUrlNavigation: 'reload',
  paramsInheritanceStrategy: 'always',
  urlUpdateStrategy: 'eager',
  canceledNavigationResolution: 'computed',
};

//https://github.com/angular/angular/issues/51601  scrollOffset: [0, 64], setOffset()
