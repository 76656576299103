export enum TrackableUserType {
  Unauthenticated = 'Not Known',
  Agency = 'Agency',
  Agent = 'Agent',
  Consumer = 'Consumer',
}

export class TrackableUser {
  public userId?: string;
  public userType: TrackableUserType;
}
