import { ErrorHandler, Injectable } from '@angular/core';
import { TrackJS } from 'trackjs';

@Injectable()
export class TrackJsErrorHandler implements ErrorHandler {
  handleError(error: { message: string; originalError?: string }) {
    const chunkFailedMessage = /Uncaught (in promise): ChunkLoadError: Loading chunk/;
    const moduleLoadFailedMessage = /Importing a module script failed/;
    const dynamicModuleFailedMessage = /Failed to fetch dynamically imported module/;

    if (
      chunkFailedMessage.test(error.message) ||
      moduleLoadFailedMessage.test(error.message) ||
      dynamicModuleFailedMessage.test(error.message)
    ) {
      window.location.reload();
    }

    if (!TrackJS.isInstalled()) {
      console.error(error);
      return;
    }
    // Add the error message to the telemetry timeline.
    // It can occasionally have useful additional context.
    console.warn(error.message);

    // Assumes we have already loaded and configured TrackJS*
    TrackJS.track(error.originalError || error);
  }
}
