import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { RMA_AB_TEST_CONFIG } from '../domain/injection-tokens';
import { AbTestExperiment } from '../domain/models/ab-test-experiment.model';
import { AbTestOptions, AbTestVariation, AbTestWeightedVariation, VariationType } from '../domain/models/ab-test-options.model';
import { ExperimentName } from '../domain/models/experiment-name.model';
import { AbTestStorageService } from '../util-storage-service/base-storage.service';

type AbTestExperiments = Record<string, AbTestExperiment>;

@Injectable({ providedIn: 'root' })
export class AbTestsService {
  private experiments: AbTestExperiments;

  public constructor(
    private readonly storageService: AbTestStorageService,
    @Inject(RMA_AB_TEST_CONFIG) configs: AbTestOptions[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(WINDOW) window: Window & { heap: any },
  ) {
    this.experiments = this.createExperiments(configs);

    if (window?.heap) {
      const heapExperiments = Object.entries(this.experiments).reduce(
        (accum, [key, value]) => ({ ...accum, [`rma_ab_${key}`]: value.selected }),
        {},
      );

      window.heap.addUserProperties(heapExperiments);
    }
  }

  public getExperiement(experimentName: ExperimentName): AbTestExperiment {
    const experiment = this.experiments[experimentName];
    if (!experiment) {
      throw new Error(`Experiment ${experimentName} has not been defined`);
    }
    return experiment;
  }

  public checkVariation(experimentName: ExperimentName, variation: VariationType): boolean {
    const experiment = this.getExperiement(experimentName);
    return experiment.selected === variation;
  }

  private createExperiments(configs: AbTestOptions[]): AbTestExperiments {
    return configs.reduce<AbTestExperiments>((accum, config) => {
      const { name } = config;
      // No duplicate experiments
      if (accum[name]) {
        throw new Error(`Experiment with name ${name} cannot be initialized twice`);
      }
      this.validateVariations(config.variations);
      return { ...accum, [name]: this.createExperiment(config) };
    }, {});
  }

  private createExperiment(config: AbTestOptions): AbTestExperiment {
    const experimentName = config.name.toLowerCase();
    const variations = this.setUpVariations(config.variations);
    const previousVariation = this.getVariationFromStorage(experimentName, variations);

    if (previousVariation) {
      const { type, name } = previousVariation;
      return this.mapSelectedToTestExperiment(experimentName, type, name);
    }

    const { type, name } = this.getRandomVariation(variations);
    this.storageService.set(experimentName, type, config.expiration ?? 7);

    return this.mapSelectedToTestExperiment(experimentName, type, name);
  }

  private mapSelectedToTestExperiment(experiment: string, variationType: VariationType, variationName?: string): AbTestExperiment {
    const name = variationName ?? variationType;
    return { selected: variationType, selectedClass: `rma-ab_exp-${experiment}_v-${name}`, name };
  }

  private setUpVariations(injectedVariations: Array<AbTestVariation | AbTestWeightedVariation>): AbTestWeightedVariation[] {
    const controlVariation: AbTestVariation = { type: 'CONTROL' };
    const variations = [...injectedVariations, controlVariation];
    const remainingWeightDistribution = this.calculateWeightDistribution(variations);

    // I don't really like this but it was nicer than reducing the accum. reducing to an array just for the weight value seemed unnecessary
    let accumulatedWeight = 0;

    return variations.map((v) => {
      accumulatedWeight += typeof v.weight !== 'undefined' ? v.weight : remainingWeightDistribution;
      return { ...v, weight: accumulatedWeight };
    });
  }

  private getVariationFromStorage(variationType: string, variations: AbTestWeightedVariation[]): AbTestWeightedVariation | undefined {
    const retreivedVariationType = this.storageService.get(variationType);
    return variations.find((v) => v.type === retreivedVariationType);
  }

  private getRandomVariation(variations: AbTestWeightedVariation[]): AbTestWeightedVariation {
    const random = Math.random() * 100;
    const matchedWeight = variations.find((variation) => random <= variation.weight);

    return matchedWeight ? matchedWeight : variations[0];
  }

  private calculateWeightDistribution(variationsWithControl: Array<AbTestVariation | AbTestWeightedVariation>): number {
    const totalVariationWeight = this.getTotalVariationWeight(variationsWithControl);
    const remainingWeight = 100 - totalVariationWeight;
    const varationsWithNoWeight = variationsWithControl.reduce((accum, v) => (typeof v.weight === 'undefined' ? accum + 1 : accum), 0);

    return varationsWithNoWeight ? remainingWeight / varationsWithNoWeight : 1;
  }

  private getTotalVariationWeight(variationsWithControl: AbTestVariation[]): number {
    const weight = variationsWithControl.reduce((accum, v) => accum + (v?.weight ?? 0), 0);

    if (weight > 99) {
      throw new Error('The total weight must not be more than 99');
    }

    return weight;
  }

  private validateVariations(variations: (AbTestVariation | AbTestWeightedVariation)[]): void {
    const count = variations.length;

    if (count < 1) {
      throw new Error('You have to provide at least one variation');
    }

    if (new Set(variations).size !== count) {
      throw new Error(`There is a duplicate variation in the  [ ${variations.map(({ name }) => name).join(', ')} ]`);
    }
  }
}
