import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    // Optional Step: Do some stuff with the url if needed.

    // If you lower it in the optional step
    // you don't need to use "toLowerCase"
    // when you pass it down to the next function

    if (url.includes('?')) {
      return this.formatUrl(url, '?');
    } else if (url.includes('#')) {
      return this.formatUrl(url, '#');
    } else {
      return super.parse(url.toLowerCase());
    }
  }

  formatUrl(url: string, separator: string): UrlTree {
    const index = url.indexOf(separator);
    const path = url.substring(0, index).toLowerCase();
    const query = url.substring(index);

    return super.parse([path, query].join(''));
  }
}
