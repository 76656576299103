import { OverlayRef } from '@angular/cdk/overlay';
import { DOCUMENT, Location } from '@angular/common';
import { ComponentRef, computed, effect, inject, Injectable, signal, ViewContainerRef } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { CurrentUserService } from '@rma/accounts/user';
import { TrackPropertyService } from '@rma/campaigns/private/api-track-campaign'; // need to move to public
import { ShortlistService } from '@rma/compare-agent/private-api/da-compare-agent'; // need to move to public
import type { RmaOverlayService } from '@rma/generic/ui/overlay';
import { PlatformService } from '@rma/generic/util/environment';
import { FeatureFlags, FeatureProviderService } from '@rma/generic/util/feature-flags';
import { LazyInject } from '@rma/generic/util/lazy-inject';
import { nonNullable } from '@rma/generic/util/operators/combined';
import { filter, firstValueFrom, map, of, switchMap, withLatestFrom } from 'rxjs';

export const TRANSLATION_SCOPE = 'siteHeader';

@Injectable()
export class SiteHeaderFacade {
  private readonly featureService = inject(FeatureProviderService);
  private readonly location = inject(Location);
  private readonly userService = inject(CurrentUserService);
  private readonly lazyInjector = inject(LazyInject);
  private readonly vcr = inject(ViewContainerRef);
  private readonly document = inject(DOCUMENT);
  private readonly trackPropertyService = inject(TrackPropertyService);
  private readonly shortlistService = inject(ShortlistService);
  private readonly platformService = inject(PlatformService);

  private readonly url = signal(this.location.path());

  private readonly state = computed(() => ({
    url: this.url(),
    sideNavVisible: signal(false),
  }));

  private readonly currentUser$ = this.userService.currentUserContext$.pipe(nonNullable());

  public readonly shortlistCount$ = this.shortlistService.shortlistCount$;
  public readonly trackPropertyCount$ = this.trackPropertyService.trackPropertyCount$;
  public readonly userType = toSignal(this.currentUser$.pipe(map(({ userType }) => userType)));

  public readonly isAuthenticated$ = this.userService.isAuthenticated$.pipe(
    filter(() => this.platformService.isPlatformBrowser || this.platformService.isPlatformBrowserBot),
  );

  // public readonly pageScrolled = fromEvent(this.document, 'scroll').pipe(
  //   takeUntilDestroyed(),
  //   distinctUntilChanged(),
  //   debounceTime(30),
  //   map(() => this.document.documentElement.scrollTop > 30),
  // );

  public readonly landerUrl$ = toObservable(this.url).pipe(
    withLatestFrom(this.isAuthenticated$),
    map(([url, isAuthenticated]) => url === '/' && !isAuthenticated),
    switchMap((showLander) => (showLander ? this.featureService.getFeature$(FeatureFlags.AgentLanderUrl, '') : of(''))),
  );

  private overlayService?: RmaOverlayService;
  private sideNavRef?: OverlayRef;

  constructor() {
    this.location.onUrlChange((url) => this.url.set(url));

    const authenticated = toSignal(this.isAuthenticated$, { initialValue: false });

    // visibility of sidenav
    effect(() => this.getSideNavComponent(this.state().sideNavVisible(), authenticated()));
  }

  public toggleSideNav() {
    this.state().sideNavVisible.update((visible) => !visible);
  }

  private async getOverlayService() {
    if (!this.overlayService) {
      const overlayService = await this.lazyInjector.get<RmaOverlayService>(() =>
        import('@rma/generic/ui/overlay').then((m) => m.RmaOverlayService),
      );

      this.overlayService = overlayService;
    }

    return this.overlayService;
  }

  private async getSideNavComponent(show: boolean, authenticated: boolean) {
    if (show) {
      const overlayService = await this.getOverlayService();

      const { SiteHeaderSidenavComponent } = await import('@rma/site/private/ui-side-nav');

      const { overlayRef, contentRef } = overlayService.open(this.vcr, SiteHeaderSidenavComponent, {
        hasBackdrop: true,
        disposeOnNavigation: true,
        scrollStrategy: overlayService.overlay.scrollStrategies.block(),
        positionStrategy: overlayService.overlay.position().global().right(),
      });

      if (contentRef instanceof ComponentRef) {
        contentRef.setInput('scope', TRANSLATION_SCOPE);
        contentRef.setInput('authenticated', authenticated);
        contentRef.setInput('userType', this.userType());

        contentRef.instance.close.subscribe(() => {
          overlayRef.detach();
          this.state().sideNavVisible.set(false);
        });
      }

      this.sideNavRef = overlayRef;

      await firstValueFrom(overlayRef.backdropClick()).then(() => {
        overlayRef.detach();
        this.state().sideNavVisible.set(false);
      });
    } else {
      this.sideNavRef?.detach();
    }
  }
}
