<nav class="text-left">
  <button
    class="text-steel-20 mb-6 flex items-center text-small font-semibold transition-all text-left"
    [class.!mb-0]="limitTo()"
    (click)="toggleLimit()"
    type="button">
    <span>
      {{ 'footer.location-links.' + verticalType + '.' + (isIndividual ? 'agents' : 'agencies') | lowercase | transloco }}
    </span>
    <span class="sr-only">{{ 'footer.location-links.view.' + (limitTo() ? 'more' : 'less') | transloco }}</span>
    <rma-icon class="ml-1" [glyph]="limitTo() ? 'expand_more' : 'expand_less'" size="small" />
  </button>

  <div
    class="flex flex-col gap-6 max-h-full transition-all"
    [ngClass]="{ '!max-h-0 overflow-hidden ': limitTo(), 'grid grid-cols-2': isMultiColumn }">
    @for (locationLink of locationLinks; track locationLink) {
      <a
        class="text-white hover:text-highlight-blue no-underline text-xs"
        [routerLink]="isIndividual ? locationLink[verticalType].agentUrl : locationLink[verticalType].agencyUrl">
        {{ locationLink.name }}
      </a>
    }
  </div>
</nav>
