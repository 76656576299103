import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'rma-site-header-login',
  standalone: true,
  imports: [RouterLink, TranslocoDirective],
  templateUrl: './login.component.html',
  host: {
    class: 'flex items-center gap-1 rounded-3xl shadow-md bg-white p-1 md:p-2',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteHeaderLoginComponent {
  public readonly scope = input.required<string>();
}
