<ng-container *transloco="let t; read: scope">
  <strong class="text-left shrink-0">{{ t('quickLinks.title') }}</strong>
  <div class="grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 shrink md:grow lg:shrink" [class.lg:!grid-cols-2]="isMultiColumn">
    @if (links) {
      <rma-footer-location-links [locationLinks]="links" [isIndividual]="true" [isMultiColumn]="isMultiColumn" verticalType="sales" />
      <rma-footer-location-links [locationLinks]="links" verticalType="sales" [isMultiColumn]="isMultiColumn" />

      @if (hasLeasing) {
        <rma-footer-location-links [locationLinks]="links" verticalType="leasing" />
      }
      @if (hasFinance) {
        <rma-footer-location-links [locationLinks]="links" [isIndividual]="true" verticalType="finance" />
      }
    }
  </div>
</ng-container>
