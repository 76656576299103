import { IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { Environment, RmaUrlsService } from '@rma/generic/util/environment';
import { getCdnUrl } from './cdn-urls';

const imageUrlFactory = (env: Environment, rmaUrls: RmaUrlsService) => {
  return (config: ImageLoaderConfig) => {
    //Handle placeholders
    const placeholders = ['placeholder/agency.jpg', 'placeholder/agent.jpg'];
    if (placeholders.includes(config.src)) {
      return rmaUrls.imageUrl(config.src);
    }

    const cdnUrl = getCdnUrl(env, config);

    if (cdnUrl) {
      return cdnUrl;
    }
    if (config.loaderParams?.['type'] === 'staticImage') {
      return rmaUrls.staticAssetUrl(config.src);
    }

    return config.src;
  };
};

export function provideCustomImageLoader() {
  return [
    {
      provide: IMAGE_LOADER,
      deps: [Environment, RmaUrlsService],
      useFactory: imageUrlFactory,
    },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        breakpoints: [576, 640, 768, 1024, 1280, 1536],
      },
    },
  ];
}
