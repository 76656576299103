<ng-container *transloco="let t; read: scope()">
  <a
    routerLink="/profile/login"
    class="rounded-3xl text-steel-100 font-normal px-4 py-1 hover:bg-blue-10 bg-transparent transition-colors ease-out duration-500"
    >{{ t('login') }}</a
  >
  <a
    routerLink="/account/join"
    data-id="join-rma-button"
    rel="nofollow"
    class="rounded-3xl bg-blue-50 text-white font-normal px-4 py-1 hover:opacity-80 transition-colors"
    >{{ t('join') }}</a
  >
</ng-container>
