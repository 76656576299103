<ng-container *transloco="let t; read: scope">
  <div [class.neon-collective-container]="!fullWidthHeader()" class="flex items-center gap-4 h-full">
    <rma-site-header-logo />

    <rma-site-header-search
      [scope]="scope"
      class="opacity-100 transition-opacity"
      [class.!opacity-0]="basicHeader()"
      [class.pointer-events-none]="basicHeader()" />

    <div
      class="grow h-full flex items-center justify-end gap-2 opacity-100 transition-opacity"
      [class.!opacity-0]="basicHeader()"
      [class.pointer-events-none]="basicHeader()">
      @defer (when compareCount()) {
        <rma-site-header-compare-agent [scope]="scope" [compareCount]="compareCount()" />
      }

      @defer (when trackedCount()) {
        <rma-site-header-track-campaign [scope]="scope" [trackedCount]="trackedCount()" />
      }

      <ng-container *rmaLet="isAuthenticated$ as isAuthenticated; suspenseRef: suspense">
        @if (!isAuthenticated) {
          <!-- <rma-site-header-navigation class="hidden md:block" [scope]="scope" displayMode="inline" /> -->

          <rma-site-header-login class="md:ml-4" [scope]="scope" />
        } @else {
          @defer (when isAuthenticated) {
            <rma-site-header-user class="md:ml-4" [scope]="scope" [userType]="userType()" />
          }
        }
      </ng-container>

      <ng-template #suspense>
        <rma-icon glyph="sync" class="animate-spin" />
      </ng-template>

      <!-- <rma-site-header-navigation class="md:hidden" [scope]="scope" displayMode="menu" (toggleSideNav)="toggleSideNav()" /> -->
    </div>
  </div>
</ng-container>
