<div class="bg-steel-100 rounded-3xl md:flex flex-col py-7 md:py-12 px-7 md:px-10">
  <div class="grid grid-cols-1 md:grid-cols-[220px_auto] gap-x-8">
    <rma-footer-contact [details]="contactDetails$ | async" [socialLinks]="socialLinks" class="order-last md:order-first xl:row-span-2" />
    <rma-footer-site-links [linkGroups]="siteLinkGroups$ | async" class="mb-10" />
    <rma-footer-quick-search
      [links]="sitemapLinks$ | async"
      [hasFinance]="hasFinance$ | async"
      [hasLeasing]="hasLeasing$ | async"
      class="md:col-span-2 xl:col-span-1" />
  </div>
</div>

<rma-legal-links [links]="legalLinks$ | async" />
