import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
import { RmaIconComponent } from '@rma/generic/ui/icon';
import { LetDirective } from '@rma/generic/ui/let';
import { SiteHeaderCompareAgentComponent } from '@rma/site/private/ui-compare-agent';
import { SiteHeaderLoginComponent } from '@rma/site/private/ui-login';
import { SiteHeaderLogoComponent } from '@rma/site/private/ui-logo';
import { SiteHeaderNavigationComponent } from '@rma/site/private/ui-navigation';
import { SiteHeaderSearchComponent } from '@rma/site/private/ui-search';
import { SiteHeaderTrackCampaignComponent } from '@rma/site/private/ui-track-campaign';
import { SiteHeaderUserComponent } from '@rma/site/private/ui-user';
import { SiteHeaderFacade, TRANSLATION_SCOPE } from './orc-header.facade';

@Component({
  selector: 'rma-site-header',
  standalone: true,
  imports: [
    LetDirective,
    RmaIconComponent,
    SiteHeaderCompareAgentComponent,
    SiteHeaderLoginComponent,
    SiteHeaderLogoComponent,
    SiteHeaderNavigationComponent,
    SiteHeaderSearchComponent,
    SiteHeaderTrackCampaignComponent,
    SiteHeaderUserComponent,
    TranslocoDirective,
  ],
  templateUrl: './orc-header.component.html',
  host: {
    class: 'fixed top-0 z-50 header-height bg-white py-2 w-full px-3 sm:px-5 md:px-6 shadow-sm hover:bg-white',
  },
  providers: [SiteHeaderFacade, provideTranslocoScope(TRANSLATION_SCOPE)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteHeaderComponent {
  private readonly facade = inject(SiteHeaderFacade);

  protected readonly scope = TRANSLATION_SCOPE;

  public readonly basicHeader = input<boolean | null>(false);
  public readonly fullWidthHeader = input<boolean | null>(false);

  //public readonly scrolledClass = toSignal(this.facade.pageScrolled.pipe(map((scrolled) => (scrolled ? 'bg-white shadow-sm' : ''))));

  public readonly isAuthenticated$ = this.facade.isAuthenticated$;
  public readonly userType = this.facade.userType;
  public readonly landerUrl = toSignal(this.facade.landerUrl$);
  public readonly compareCount = toSignal(this.facade.shortlistCount$, { initialValue: 0 });
  public readonly trackedCount = toSignal(this.facade.trackPropertyCount$, { initialValue: 0 });

  protected toggleSideNav() {
    this.facade.toggleSideNav();
  }
}
