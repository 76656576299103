<ng-container *transloco="let t; read: scope">
  <div class="grid grid-cols-1 xs:grid-cols-2 lg:flex lg:flex-row justify-between gap-4">
    @for (group of linkGroups; track $index) {
      <div class="flex flex-col">
        <div class="font-medium text-steel-40 mb-4 lg:mb-8">{{ t(group.title) }}</div>
        @for (link of group.links; track $index) {
          <rma-footer-link theme="primary" [footerLink]="link" class="mb-4 lg:mb-8" />
        }
      </div>
    }
  </div>
</ng-container>
