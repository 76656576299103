import { Injectable, NgZone } from '@angular/core';
import { PlatformService } from '@rma/generic/util/environment';
import { UserTrackerService } from '@rma/generic/util/tracking/analytics';
import { FacebookPixelScriptService } from '@rma/generic/util/tracking/facebook-pixel';
import { HotjarScriptService } from '@rma/generic/util/tracking/hotjar';
import { IntercomService } from '@rma/generic/util/tracking/intercom';

@Injectable()
export class LazyLoadThirdPartyScriptsService {
  public constructor(
    private readonly facebookPixelScriptService: FacebookPixelScriptService,
    private readonly hotjarScriptService: HotjarScriptService,
    private readonly intercom: IntercomService,
    private readonly platformService: PlatformService,
    private readonly userTrackerService: UserTrackerService,
    private readonly zone: NgZone,
  ) {}

  public initialize() {
    this.zone.runOutsideAngular(() => {
      this.intercom.initialise();
      // hotjarScriptService.appendScript();
      this.userTrackerService.watchUser();
      if (this.platformService.isTrackable) {
        this.facebookPixelScriptService.appendScript();
      }
    });
  }
}
