import { inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CurrentUserService, UserType } from '@rma/accounts/user';
import { map } from 'rxjs/operators';
import { TrackableUserType } from '../domain';
import { GoogleTagManagerService } from '../feat-google-tag-manager/google-tag-manager.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserTrackerService {
  private readonly googleTagManagerService = inject(GoogleTagManagerService);
  private readonly currentUserService = inject(CurrentUserService);

  public watchUser() {
    this.currentUserService.currentUserContext$
      .pipe(
        map((x) => (x ? { userType: this.mapUserType(x.userType), userId: x.userId } : { userType: TrackableUserType.Unauthenticated })),
        untilDestroyed(this),
      )
      .subscribe((x) => this.googleTagManagerService.trackUser(x));
  }

  private mapUserType(userType: UserType) {
    switch (userType) {
      case 'Agency':
        return TrackableUserType.Agency;
      case 'Agent':
        return TrackableUserType.Agent;
      case 'Consumer':
        return TrackableUserType.Consumer;
      default:
        return TrackableUserType.Unauthenticated;
    }
  }
}
