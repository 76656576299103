<ng-container *transloco="let t; read: scope">
  @if (footerLink.href) {
    <a [href]="footerLink.link" [class]="linkThemeStyles">{{
      (footerLink.label | isTranslationObject) ? t(footerLink.label.key) : footerLink.label
    }}</a>
  } @else {
    <a [routerLink]="footerLink.link" [class]="linkThemeStyles" [queryParams]="footerLink.queryParams">{{
      (footerLink.label | isTranslationObject) ? t(footerLink.label.key) : footerLink.label
    }}</a>
  }
</ng-container>
